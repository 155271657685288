
import React, {useEffect, useState}  from "react";
import { Link } from "react-router-dom"
import useAuth from '../hooks/useAuth';
import './Landing.css';
import Logo from "./logo.png"

const Landing = () => {

    const { auth } = useAuth();
    const [fileData, setFileData] = useState("");

   /* const openPdf = () => {
        window.open('https://propietarios.pueblonuevo.com.ar/backend/pdf', '_blank');
    };*/

    useEffect(() => {
        if (!auth?.user) {
            localStorage.removeItem("user");
            //console.log("landing vacio storage auth" );
        }
      }, []);
    
      
    return (
        <section className="landing-container">
            <div className="top">
                <img src={Logo} alt="" className="landing-logo"/>
            </div>    
            
            <h1 className="landing-nombre">Pueblo Nuevo</h1>
            
            <div className="middle">
                <Link to="/login">Login</Link>
            </div>
            <div className="bottom">
                <p style={{ margin: "10px",
                            padding: "10px",
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "center",
                            backgroundColor: "transparent",
                            borderRadius: "1px",
                            border: "0px solid white",
                            width: "400px",
                            height: "auto",
                            color: "white",
                        }}>Web privada para clientes - LoteoSoft - 2024</p>
                <p style={{ margin: "10px",
                            padding: "10px",
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "center",
                            backgroundColor: "transparent",
                            borderRadius: "0px",
                            border: "0px solid white",
                            width: "400px",
                            height: "auto",
                            color: "gray",
                        }}>
                            version 1.23.10.47
                </p>
            </div>
      </section>
    )
}

export default Landing
