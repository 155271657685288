import React, { createContext, useState, useEffect } from "react";

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [auth, setAuth] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  // Utiliza un efecto para verificar y cargar datos de usuario desde localStorage
  useEffect(() => {
    const storedAuth = localStorage.getItem("auth");
    if (storedAuth) {
      setAuth(JSON.parse(storedAuth));
      //console.log ("storage auth contexto",storedAuth);
    } else {
      //console.log ("storage auth contexto vacio");
    }
    setIsLoading(false); 
  }, []);

  // Utiliza un efecto para actualizar localStorage cada vez que el estado de auth cambie
  useEffect(() => {
    if (auth?.user) {
      localStorage.setItem("auth", JSON.stringify(auth));
      console.log ("cambió auth contexto",auth);
    } else {
      localStorage.removeItem("auth");
      //console.log ("vació auth contexto");
    }
  }, [auth]);

  if (isLoading) {
    return <div>Loading...</div>;  // Renderizar un componente de carga mientras se carga el estado de autenticación
  }

  return (
    <AuthContext.Provider value={{ auth, setAuth }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
