import React from 'react';

const LoteSelection = ({ manzanas }) => {
  const lotesPorFila = Math.max(...manzanas.map(manzana => manzana.lotes));
  const size = 50;
  const padding = 20;

  const handleLoteClick = (manzanaIndex, loteIndex) => {
    console.log(`Manzana seleccionada: ${manzanas[manzanaIndex].numero}, Lote seleccionado: ${loteIndex + 1}`);
  };

  return (
    <svg
      width={lotesPorFila * (size + padding) + padding}
      height={manzanas.length * (size + padding) + padding}
      viewBox={`0 0 ${lotesPorFila * (size + padding) + padding} ${manzanas.length * (size + padding) + padding}`}
    >
      {manzanas.map((manzana, manzanaIndex) => {
        return (
          <React.Fragment key={`manzana-${manzanaIndex}`}>
            {/*Cuadrado del lote*/}
            {Array.from({ length: manzana.lotes }).map((_, loteIndex) => {
              const x = loteIndex * (size + padding) + padding;
              const y = manzanaIndex * (size + padding) + padding;

              return (
                <rect
                  key={`${manzanaIndex}-${loteIndex}`}
                  x={x}
                  y={y}
                  width={size}
                  height={size}
                  fill={(manzanaIndex % 2 === 0)?"green":"blue" }
                  opacity="0.8" 
                  onClick={() => handleLoteClick(manzanaIndex, loteIndex)}
                />
              );
            })}
            {/*Número de manzana*/}
            <text
              x={padding}
              y={(manzanaIndex + 1) * (size + padding) + padding / 2}
              fontSize="14"
              fill={(manzanaIndex % 2 !== 0)?"green":"blue" }
            >
              Manzana {manzana.numero}
            </text>
            {/*Número de lote*/}
            {Array.from({ length: manzana.lotes }).map((_, loteIndex) => {
              const x = loteIndex * (size + padding) + padding;
              const y = manzanaIndex * (size + padding) + padding;

              return (
                <text
                  key={`lote-text-${manzanaIndex}-${loteIndex}`}
                  x={x + size / 2 - 5}
                  y={y + size / 2}
                  fontSize="14"
                  fill={(manzanaIndex % 2 !== 0)?"green":"blue" }
                  textAnchor="middle"
                  dominantBaseline="central"
                >
                 Lote {loteIndex + 1}
                </text>
              );
            })}
          </React.Fragment>
        );
      })}
    </svg>
  );
};

export default LoteSelection;
