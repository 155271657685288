import React, { useContext, useRef, useEffect, useState } from "react";
import CartContext from "../context/CartContext";
import LanguageContext from "../context/LanguajeContext";
import { PayPalScriptProvider, PayPalButtons } from '@paypal/react-paypal-js';
import { Card } from "@material-ui/core";
import axios from "axios";
import mercadopago from './mercadopago.png';
import carritoTachado from './carritoTachado.png';
import useAuth from '../hooks/useAuth';


const translations = {
  en: {
    lote: "Lot",
    manzana: "Block",
    contrato: "Contracts",
    fechaFirma: "Signing Date",
    moneda: "Currency",
    formaDePago: "Payment Method",
    totalContratos: "Total contracts",
    cuotas: "Installments",
    nroCuota: "Installment number",
    vencimiento: "Expiration",
    importe: "Amount",
    VaciarCarrito: "Empty Cart",
    carritoVacio: "No elements in the cart",
    carroDeCompras: "Cart",
    cancelar: "Cancel",
    cuotaMasComision: "Total",
    Total: "Total amount"
  },
  es: {
    lote: "Lote",
    manzana: "Manzana",
    contrato: "Contratos",
    fechaFirma: "Fecha de firma",
    moneda: "Moneda",
    formaDePago: "Forma de Pago",
    totalContratos: "Total de contratos",
    cuotas: "Cuotas",
    nroCuota: "Número de cuota",
    vencimiento: "Vencimiento",
    importe: "Importe",
    VaciarCarrito: "Vaciar Carrito",
    carritoVacio: "Carrito vacío",
    carroDeCompras: "Carrito de compras",
    cancelar: "Cancelar",
    comisionCobranza:"Comisión Cobranza",
    cuotaMasComision: "Total",
    Total: "Total a pagar"
  },
  pt: {
    lote: "Lote",
    manzana: "Bloco",
    contrato: "Contratos",
    fechaFirma: "Data de Assinatura",
    moneda: "Moeda",
    formaDePago: "Método de Pagamento",
    totalContratos: "Total de contratos",
    cuotas: "Parcelas",
    nroCuota: "Número da parcela",
    vencimiento: "Vencimento",
    importe: "Valor",
    VaciarCarrito: "Esvaziar Carrinho",
    carritoVacio: "Nenhum item no carrinho",
    carroDeCompras: "Carrinho",
    cancelar: "Cancelar",
    cuotaMasComision: "Total",
    Total: "Valor Total"
  }
};


const getMercadoPagoRedirectUrl = async (cart) => {
  try {
    const response = await axios.post(
      'https://propietarios.pueblonuevo.com.ar/backend/generar',
      {
        cuotas: cart,
        fecha: new Date().toISOString().slice(0, 10),
      },
      {
        headers: {
          'Content-Type': 'application/json'
        }
      }
    );
    const mercadoPagoUrl = response.data.init_point;
    return mercadoPagoUrl;
  } catch (error) {
    console.error('Error getting MercadoPago redirect URL:', error);
  }
};

const Cart = () => {
  const { cart, setCart, clearCart } = useContext(CartContext);
  const { language } = useContext(LanguageContext);
  const _isMounted = useRef(false);
  const [totalImporte, setTotalImporte] = useState(0);
  const { auth} = useAuth();

  const text = translations[language];

  const handlePaymentSuccess = async (paymentData) => {
    try {
      console.log (paymentData);
      const response = await axios.post(
        "https://propietarios.pueblonuevo.com.ar/backend/grabarPagosPayPal",
        {
          orderID: paymentData.orderID,
          fecha: new Date().toISOString().slice(0, 10),
          cuotas: cart,
        }
      );

      clearCart();
    } catch (error) {
      console.error("Error al registrar pagos en el servidor:", error);
    }
  };
  

  useEffect(() => {
    _isMounted.current = true;
    return () => {
      _isMounted.current = false;
    };
  }, []);


  useEffect(() => {
    calculateTotalImporte(cart);
  }, [cart]);
  

  const calculateTotalImporte = (cartItems) => {
    const total = cartItems.reduce((accumulator, item) => {
      // Calcular la comisión y convertirla a un número con dos decimales
      let Comision = 0;
      if (auth.PayPal ===1) 
          Comision = parseFloat((auth.comisionPayPal * item.Total / 100).toFixed(2));
      else
          Comision = parseFloat((auth.comisionMercadoPago * item.Total / 100).toFixed(2));

      // Calcular total con comisión
      const totalConComision = parseFloat(item.Total) + Comision;
      // Agregar el campo totalConComision al item
      item.totalConComision = totalConComision;
      // Usar totalConComision para el acumulador
      return accumulator + totalConComision;
    }, 0);
  
    setTotalImporte(parseFloat((total).toFixed(2)));
  };
  

  const handleCancel = (cuotaSik) => {
    const newCart = cart.filter((item) => item.Sik !== cuotaSik);
    if (_isMounted.current) {
      setCart(newCart);
    }
  };

  const CuotaItem = (cuota) => (
    <Card
      style={{
        margin: "10px",
        padding: "10px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "#080808",
        borderRadius: "10px",
        border: "2px solid white",
        width: "240px",
        height: "auto",
        color: "white",
      }}
    >
      <div>
        <span style={{ fontSize: 14, fontWeight: "bold" }}>
          {text.contrato}&nbsp;&nbsp;
          {cuota.Contrato}
        </span>
      </div>
      <div>
        <span style={{ fontSize: 14, fontWeight: "bold" }}>{text.nroCuota}
        &nbsp;&nbsp; {cuota.NroCuota}
        </span>
      </div>

      <div>
        <span style={{ fontSize: 14, fontWeight: "bold" }}>
          {text.vencimiento} &nbsp;&nbsp;
          {cuota.FechaCuota}
        </span>
      </div>
      <div>
        <span style={{ fontSize: 14, fontWeight: "bold" }}>
          {text.importe}&nbsp;&nbsp;
          {cuota.Total}
        </span>
      </div>
      <div>
        <span style={{ fontSize: 12, fontWeight: "bold" }}>
          {text.comisionCobranza}&nbsp;&nbsp;
          {(auth.comisionPayPal * cuota.Total / 100).toFixed(2)}
        </span>
      </div>
      <div>
        <span style={{ fontSize: 14, fontWeight: "bold" }}>
          {text.cuotaMasComision}&nbsp;&nbsp;
          {cuota.totalConComision}
        </span>
      </div>


      <div>
        <button
          style={{
            fontSize: 14,
            fontWeight: "bold",
            width: "90px",
            marginTop: "10px",
            color:"#010F10", 
            backgroundColor:"#68fb9b"

          }}
          onClick={() => handleCancel(cuota.Sik)}
        >
          {text.cancelar}
        </button>
      </div>
    </Card>
  );

  const [walletAddress, setWalletAddress] = useState("");

  useEffect(() => {
    getCurrentWalletConnected();
    addWalletListener();
  }, [walletAddress]);

  const connectWallet = async () => {
    if (typeof window != "undefined" && typeof window.ethereum != "undefined") {
      try {
        /* MetaMask is installed */
        const accounts = await window.ethereum.request({
          method: "eth_requestAccounts",
        });
        setWalletAddress(accounts[0]);
      } catch (err) {
        console.error(err.message);
      }
    } else {
      /* MetaMask is not installed */
      console.log("Please install MetaMask");
    }
  };

  const getCurrentWalletConnected = async () => {
    if (typeof window != "undefined" && typeof window.ethereum != "undefined") {
      try {
        const accounts = await window.ethereum.request({
          method: "eth_accounts",
        });
        if (accounts.length > 0) {
          setWalletAddress(accounts[0]);
        } else {
          console.log("Connect to MetaMask using the Connect button");
        }
      } catch (err) {
        console.error(err.message);
      }
    } else {
      /* MetaMask is not installed */
      console.log("Please install MetaMask");
    }
  };

  const addWalletListener = async () => {
    if (typeof window != "undefined" && typeof window.ethereum != "undefined") {
      window.ethereum.on("accountsChanged", (accounts) => {
        setWalletAddress(accounts[0]);
        console.log(accounts[0]);
      });
    } else {
      /* MetaMask is not installed */
      setWalletAddress("");
      console.log("Please install MetaMask");
    }
  };
  
  return (
    <div
      style={{
        paddingTop: "12%", 
        padding: "25px 25px",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        backgroundColor: "#01240F",
        width: "100%",
        height: "100%",
        borderRadius: "10px",
        border:"0px"
      }}
    >
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "repeat(auto-fit, minmax(240px, 1fr))",
          gridGap: "10px",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          minHeight: "calc(100% - 50px)"
        }}
      >
        {cart &&
          cart.map((cuota) => <CuotaItem key={cuota.Sik} {...cuota} />)}
      </div>

      <div style={{
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          margin:"10px",
          marginTop:"30px",
          padding:"30px"
        }}>
        {cart && cart.length > 0 ? <p>{text.Total}: ${totalImporte} </p> : (null) }
      </div>

      <div
        style={{
          id:"divFormasDePago",
          margin: "10px",
          marginTop: "30px",
          padding: "10px",
          border: "2px solid grey",
          borderRadius: "10px",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          width: "100%",
        }}
      >
        {cart && cart.length > 0 && 
        <button
              style={{ padding: '0', border: '5px', backgroundColor: "#01240F", }}
                onClick={async () => {
                  clearCart();
                }}
              >
                <img src={carritoTachado} alt="Vaciar carrito" style={{ width: '20%', height: '50px' }} />
                {text.VaciarCarrito}
              </button>
      }

      {cart  && (cart.length === 0) &&
        
        <text> Sin elementos para pagar</text>
      }
      {/* ID      Ac5WphtpsmF9EvoU1uF79MgClLAfsDm90TWM5_2pjGcCEtnSY7IlLI250VeSjOxVmTcwXV_1UjxQGgjU
          Secret  ELkEUx1IgpUDKqKQgG9aGA6v46S_RyAlo-LWB7prZoArVVSilR3OwIMhu_UdU0yXQ0wcUJ4Z2d1I-utW
          Morit@2007
      */}
      {cart && cart.length > 0 && (auth.PayPal ===1) && 
          <PayPalScriptProvider
                  options = {{"client-id":"Af4PlMVjxwkCvR8o5cTeNpfqSApE4smGTJBjdwyfNusOfOyAPr_4Kf889HHgiAWWdVvoR650nqTvhKKz",
                  components: "buttons",
                  locale: "en_US",
                  currency: "USD"}}>
                <PayPalButtons 
                  key={totalImporte} 
                  createOrder={(data, actions) => {
                        return actions.order
                            .create({
                                purchase_units: [
                                    {
                                        amount: {
                                            value: `${totalImporte.toFixed(2)}`,
                                        },
                                        currency_code: "USD",
                                        description: "Cuotas Pueblo Nuevo"
                                    },
                                ],
                            })
                            .then((orderId) => {
                                // Your code here after create the order
                                console.log ("Order ID", orderId);
                                return orderId;
                            });
                    }}
                    onApprove={function (data, actions) {
                      return actions.order.capture().then(function () {
                          handlePaymentSuccess(data, cart);
                      });
                    }}/>
                  </PayPalScriptProvider>
      }
          {cart && cart.length > 0 && (auth.MercadoPago ===1) && (
              <button
              style={{ padding: '0', border: '5px', backgroundColor: "#ffffff", }}
                onClick={async () => {
                  const mercadoPagoUrl = await getMercadoPagoRedirectUrl(cart);
                  if (mercadoPagoUrl) {
                    window.open(mercadoPagoUrl, '_blank');
                    clearCart();
                  } else {
                    console.log ("mercadoPagoUrl vacia", mercadoPagoUrl);
                  }
                }}
              >
                <img src={mercadopago} alt="Mercadopago" style={{ width: '90%', height: '90%' }} />
              </button> )}

              {/*<button
                className="button is-white connect-wallet"
                onClick={connectWallet}
              >
                <span className="is-link has-text-weight-bold">
                  {walletAddress && walletAddress.length > 0
                    ? `Connected: ${walletAddress.substring(
                        0,
                        6
                      )}...${walletAddress.substring(38)}`
                    : "Connect Wallet"}
                </span>
                    </button>*/}
      </div>
</div>
);
};

export default Cart;