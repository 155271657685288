import React, { useContext, useEffect, useState, useMemo } from "react";
import { Card } from "@material-ui/core";
import ContratoContext from "./../context/ContratosContext";
import LanguageContext from "../context/LanguajeContext";
import { CartContext } from "../context/CartContext";
import useAuth from '../hooks/useAuth';

import Axios from "axios";

const Cuotas = () => {
  
  const { selectedContrato } = useContext(ContratoContext);
  const [contratoActual, setContratoActual] = useState([{ message: "vacio" }]);
  const [cuotas, setCuotas] = useState(null);
  const [cuotasActual, setCuotasActual] = useState([{ message: "vacio" }]);
  const { addToCart, cart } = useContext(CartContext);
  const { language } = useContext(LanguageContext);
  const [todasChecked, setTodasChecked] = useState(false);
  const [impaidInstallments, setImpaidInstallments] = useState(0);
  const memoizedCuotas = useMemo(() => cuotas, [cuotas]);
  const { auth} = useAuth();
  

  const currentDate = new Date();

  const translations = {
    en: {
      lote: "Lot",
      manzana: "Block",
      contrato: "Contract",
      fechaFirma: "Signing Date",
      moneda: "Currency",
      formaDePago: "Payment Method",
      totalContratos: "Total contracts",
      cuotas: "Installments",
      nroCuota: "Installment number",
      vencimiento: "Expiration",
      importe: "Amount",
      cuotaAgregada: "Installment Added to the Cart",
      ValorUVA: "UVA Value",
      fechaPago: "Payment date",
      punitorios:"Punitive",
      capital:"Capital",
      monFinal: "Amount",
      total: "Amount",
      simbolo: (moneda) => moneda === 2 ? "U$S" : "$",
      Todas: "See all installments"
    },
    es: {
      lote: "Lote",
      manzana: "Manzana",
      contrato: "Contrato",
      fechaFirma: "Fecha de firma",
      moneda: "Moneda",
      formaDePago: "Forma de Pago",
      totalContratos: "Total de contratos",
      cuotas: "Cuotas",
      nroCuota: "Número de cuota",
      vencimiento: "Venc.",
      importe: "Importe",
      cuotaAgregada: "Cuota agregada al carrito",
      ValorUVA: "Valor UVA",
      fechaPago: "Fecha de pago",
      punitorios:"Punitorios",
      capital:"Capital",
      total: "Total",
      monFinal: "Monto",
      simbolo: (moneda) => moneda === 2 ? "U$S" : "$",
      Todas: "Ver todas las cuotas"
    },
    pt: {
      lote: "Lote",
      manzana: "Bloco",
      contrato: "Contrato",
      fechaFirma: "Data de assinatura",
      moneda: "Moeda",
      formaDePago: "Forma de pagamento",
      totalContratos: "Total de contratos",
      cuotas: "Parcelas",
      nroCuota: "Número da parcela",
      vencimiento: "Vencimento",
      importe: "Valor",
      cuotaAgregada: "Parcela adicionada ao carrinho",
      ValorUVA: "Valor UVA",
      fechaPago: "Data de pagamento",
      punitorios:"Punitivo",
      capital:"Capital",
      monFinal: "Valor",
      total: "Valor",
      simbolo: (moneda) => moneda === 2 ? "U$S" : "$",
      Todas: "Ver todas as parcelas"
    }
  };
  
  const [addedToCart, setAddedToCart] = useState(new Set());

 
  const text = translations[language];

  useEffect(() => {
    const newAddedToCart = new Set();
    cart.forEach((item) => {
      newAddedToCart.add(item.Sik);
    });
    setAddedToCart(newAddedToCart);
  }, [cart]);

  useEffect(() => {
    if (selectedContrato.Sik) setContratoActual(selectedContrato.Sik);
  }, [selectedContrato]);

  useEffect(() => {
    if (contratoActual && !(contratoActual.length === 1 && contratoActual[0].message === "vacio" && contratoActual.message !== "vacio")) {
        console.log ("Llamé");
        traeCuotas();
    }
  }, [contratoActual, todasChecked]);

  useEffect(() => {
    if (cuotas) {
      let impaidCount = 0;
      cuotas.forEach(cuota => {
        const fechaImpaga = cuota.FechaCuota;
        const fechaPago = cuota.FechaPago;
  
        const isValidFechaPago = (fechaPago) => {
          const regex = /^(0?[1-9]|1[0-9]|2[0-9]|3[01])\/(0?[1-9]|1[0-2])\/\d{4}$/;
          return regex.test(fechaPago);
        };
  
        if(fechaImpaga && !isValidFechaPago(fechaPago)) {
          const [dia, mes, anio] = fechaImpaga.split("/");
          const fechaImpagaDate = new Date(anio, mes - 1, dia);
          if (fechaImpagaDate < currentDate) {
              impaidCount++;
              cuota.estaVencida = 1;
          }
        }
      });
        console.log ("Cuotas.php umpaid", impaidCount);
        setImpaidInstallments(impaidCount);
        setCuotasActual(cuotas);
      } 
  }, [cuotas]);

  const esCuotaDelMesActual = (fechaCuota) => {

    if (!fechaCuota) return false;

    const fechaActual = new Date();
    const [dia, mes, anio] = fechaCuota.split("/");
    const fechaCuotaDate = new Date(anio, mes - 1, dia);
  
    return fechaCuotaDate.getMonth() === fechaActual.getMonth() &&
           fechaCuotaDate.getFullYear() === fechaActual.getFullYear();
  };

  const esCuotaDelMesActualOMesSiguiente = (fechaCuota) => {
    if (!fechaCuota) return false;
  
    const fechaActual = new Date();
    const mesActual = fechaActual.getMonth();
    const anioActual = fechaActual.getFullYear();
  
    const [dia, mes, anio] = fechaCuota.split("/").map(Number);
    const fechaCuotaDate = new Date(anio, mes - 1, dia);
    const mesCuota = fechaCuotaDate.getMonth();
    const anioCuota = fechaCuotaDate.getFullYear();

    /* Comprueba si la cuota es del mes actual o del siguiente
    if (anioCuota === anioActual) {
      console.log ("fechaCuota 1", fechaCuota, mesCuota === mesActual || mesCuota === mesActual + 1);  
    } else if (anioCuota === anioActual + 1) {
      console.log ("fechaCuota 2", fechaCuota, mesCuota === 0 && mesActual === 11); 
    } else {
      console.log ("NO CORRESPONDE", fechaCuota);
    }*/
  
    // Comprueba si la cuota es del mes actual o del siguiente
    if (anioCuota === anioActual) {
      return mesCuota === mesActual || mesCuota === mesActual + 1;
    } else if (anioCuota === anioActual + 1) {
      return mesCuota === 0 && mesActual === 11;
    } else {
      return false;
    }
  };
  

  const traeCuotas = () => {
    console.log ("traecuotas", contratoActual, todasChecked);
    Axios.post(
      "https://propietarios.pueblonuevo.com.ar/backend/cuotas",
      {
        contrato: contratoActual,
        todas: todasChecked, // Agrega el parámetro "todas" con el valor de "todasChecked"
      },
      {
        headers: {
          "Access-Control-Allow-Origin": "https://propietarios.pueblonuevo.com.ar",
        },
      }
    ).then((response) => {
      if (response.data.message) {
        //setCuotasStatus(response.data.message);
      } else {
        console.log("traje cuotas", response.data, contratoActual);
        setCuotas(response.data);
      }
    });
  };

  const handleAddToCart = (cuota) => {
    // Verificar si hay elementos en el carrito y obtener la moneda del carrito
    const monedaCarrito = cart.length > 0 ? cart[0].Moneda : null;
    
    if (cuota.Moneda !== 2 && auth.MercadoPago === 0) {
      alert("Las cuotas en pesos no pueden pagarse en PayPal");
    }
    else if (cuota.Moneda === 2 && auth.PayPal === 0) {
      alert("Las cuotas en dólares no pueden pagarse en MercadoPago");
    } else 
    // Si el carrito está vacío o si la moneda de la cuota coincide con la del carrito
    if (!monedaCarrito || cuota.Moneda === monedaCarrito) {
      addToCart(cuota);
      setAddedToCart((prevAddedToCart) => new Set([...prevAddedToCart, cuota.Sik]));
      alert("Cuota agregada al carrito");
    } else {
      // Mostrar mensaje de error si las monedas no coinciden
      alert("No se pueden agregar cuotas de diferentes monedas al carrito.");
    }
  };
  

  const CuotaItem = (cuota) => {
    const fechaImpaga = cuota.FechaCuota;
    const fechaPago = cuota.FechaPago;

    const isValidFechaPago = (fechaPago) => {
      const regex = /^(0?[1-9]|1[0-9]|2[0-9]|3[01])\/(0?[1-9]|1[0-2])\/\d{4}$/;
      return regex.test(fechaPago);
    };
    
    const tieneFechaPago = fechaPago && isValidFechaPago(fechaPago);
    let fecha = null;
  
    const simboloMoneda = text.simbolo(cuota.Moneda);  
    //console.log ("cuota",cuota, tieneFechaPago, fechaPago);

    if (fechaImpaga) {
      const [dia, mes, anio] = fechaImpaga.split("/");
      fecha = new Date(anio, mes - 1, dia);
    }
  
    const cuotaInCart = cart.find((item) => item.Sik === cuota.Sik);

    const openPdf = () => {
      window.open(`https://propietarios.pueblonuevo.com.ar/backend/pdf/${cuota.Sik}`, '_blank');
    };

    const puedeImprimirse = !tieneFechaPago && 
          impaidInstallments <= 3 && 
          (cuota.estaVencida || esCuotaDelMesActualOMesSiguiente(cuota.FechaCuota) || cuota.Moneda === 2);

    return (
      <Card
        style={{
          margin: "10px",
          padding: "10px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-start",
          alignItems: "center",
          backgroundColor: "#080808",
          borderRadius: "10px",
          border: "2px solid",
          borderColor: tieneFechaPago ? "gray" : fecha && fecha < currentDate ? "red" : "#00FF00",
          width: "200px",
          height: "299px",
          color: "white",
        }}
      >
        <div>
          <span style={{ fontSize: 14, fontWeight: "bold" }}>
            {text.contrato}&nbsp;&nbsp;
            {cuota.Contrato}
          </span>
        </div>
        <div>
          <span style={{ fontSize: 14, fontWeight: "bold" }}>
            {text.nroCuota}&nbsp;&nbsp;
            {cuota.NroCuota}
          </span>
        </div>

        {(cuota.Moneda === 3) && (<div>
          <span style={{ fontSize: 10, fontWeight: "bold" }}>
            {text.vencimiento}&nbsp;&nbsp;
            {cuota.FechaCuota}&nbsp;&nbsp;
            {cuota.CuotaEnUVA} UVAS
          </span>
        </div>)
        }

        {(cuota.Moneda === 1) && (<div>
          <span style={{ fontSize: 12, fontWeight: "bold" }}>
            {text.vencimiento}&nbsp;&nbsp;
            {cuota.FechaCuota}&nbsp;&nbsp;
          </span>
        </div>)
        }

        {(cuota.Moneda === 2) && (<div>
          <span style={{ fontSize: 12, fontWeight: "bold" }}>
            {text.vencimiento}&nbsp;&nbsp;
            {cuota.FechaCuota}&nbsp;&nbsp;
          </span>
        </div>)
        }
  
        {tieneFechaPago && (
          <div>
            <span style={{ fontSize: 12, fontWeight: "bold" }}>
              {text.fechaPago}&nbsp;&nbsp;
              {cuota.FechaPago}
            </span>
          </div>
        )}
  
        {!tieneFechaPago && (cuota.Moneda === 3) && (
          <div>
            <span style={{ fontSize: 10, fontWeight: "bold" }}>
              {text.ValorUVA} &nbsp;&nbsp;
              ${cuota.UVACalculoCapital} &nbsp;&nbsp; {cuota.FechaUVACalculoCapital} 
            </span>
          </div>
        )}
  
        {tieneFechaPago && (
          <div>
            <span style={{ fontSize: 14, fontWeight: "bold" }}>
              {text.monFinal}&nbsp;&nbsp;
              {simboloMoneda}{cuota.MonFinal}
            </span>
          </div>
        )}

        {!tieneFechaPago && (
          <div>
            <span style={{ fontSize: 12, fontWeight: "bold" }}>
              {text.capital}&nbsp;&nbsp;
              {simboloMoneda}{cuota.Capital} 
            </span>
          </div>
        )}

        {!tieneFechaPago && cuota.Punitorios > 0 && (
          <div>
            <span style={{ fontSize: 12, fontWeight: "bold" }}>
              {text.punitorios}&nbsp;&nbsp;
              {simboloMoneda}{cuota.Punitorios}
            </span>
          </div>
        )}

        {!tieneFechaPago && (
          <div>
            <span style={{ fontSize: 13, fontWeight: "bold" }}>
              {text.total}&nbsp;&nbsp;
              {simboloMoneda}{cuota.Total}
            </span>
          </div>
        )}

        {tieneFechaPago && (
          <div>
            <span style={{ display: "inline-block", fontSize: 15, fontWeight: "bold", color: "black", backgroundColor: "gray", marginTop: "50px" }}>
              CANCELADA
            </span>
          </div>
        )}
  
        {(!tieneFechaPago && !cuotaInCart  && puedeImprimirse && (auth.MercadoPago === 1 || auth.PayPal === 1)) && (
        <button
          onClick={() => handleAddToCart(cuota)}
          style={{
            fontSize: "14px",
            backgroundColor: fecha && fecha < currentDate ? "red" : "#68fb9b",
            color: fecha && fecha < currentDate ? "#FFF" :  "#010F10",
            fontWeight: "bold",
            borderRadius: "5px",
            padding: "5px",
            border: "none",
            cursor: "pointer",
            marginTop: "10px",
          }}
        >
          Agregar al carrito
        </button>
      )}

      {(!tieneFechaPago  && puedeImprimirse)  && (    
          <button 
          style={{
            fontSize: "10px",
            backgroundColor: fecha && fecha < currentDate ? "red" : "#68fb9b",
            color: fecha && fecha < currentDate ? "#FFF" :  "#010F10",
            fontWeight: "bold",
            borderRadius: "5px",
            padding: "5px",
            border: "none",
            cursor: "pointer",
            marginTop: "10px",
          }}
          
          onClick={openPdf}>Abrir PDF</button>
      )}   
      </Card>
    );
  };
  

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        marginTop: "30px",
      }}
    >
        <div>
          {(impaidInstallments) > 3 && (
            <div>
              <span style={{ display: "inline-block", fontSize: 15, fontWeight: "bold", color: "yellow", backgroundColor: "black", marginTop: "50px",justifyContent: "center",
                alignItems: "center", }}>
                SI HAY MAS DE TRES CUOTAS VENCIDAS NO PUEDE OPERAR EN LA PÁGINA - COMUNÍQUESE CON NUESTRA OFICINA
              </span>
            </div>
          )}
          <div style={{ fontSize: 16, fontWeight: "bold", color:"#FFFFFF",display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center", }}>
            <input
              type="checkbox"
              checked={todasChecked}
              onChange={(e) => setTodasChecked(e.target.checked)}
            /> &nbsp;&nbsp;
            <label htmlFor="todas" style={{ fontSize: 16, fontWeight: "bold", color:"#FFFFFF",display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center", }}>{text.Todas}
            </label>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
              flexWrap: "wrap",
              width: "100%",
            }}
          >
            {cuotasActual.map((cuota) => (
              <CuotaItem key={cuota.NroCuota} {...cuota} />
            ))}
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              textJustify: "auto",
              width: "100%",
            }}
          >
            Total de cuotas: {cuotasActual.length}
          </div>
        </div>
    </div>
  );
};

export default Cuotas;
