import { useRef, useState, useEffect, useContext } from 'react';
import useAuth from '../hooks/useAuth';
import {useNavigate, useLocation, Link } from 'react-router-dom';
import ClienteContext from "./../context/ClienteContext";
import LanguageContext from "../context/LanguajeContext";
import Logo from "./logo.png"
import "./Login.css"
import { SpinnerCircular } from 'spinners-react';
import axios from '../api/axios';

const translations = {
    en: {
      usuario: "User",
      password: "Password",
      olvido: "Forgot your password?", 
      enter:"Enter", 
      errLogin:"User/password incorrect",
      showPass:"Show password" 
    },
    es: {
        usuario: "Usuario",
        password: "Contraseña",
      olvido: "¿Olvidó su contraseña?", 
      enter:"Entrar", 
      errLogin:"Usuario/contraseña incorrectos",
      showPass:"Mostrar contraseña" 
    },
    pt: {
        usuario: "Do utilizador",
        password: "Senha",
      olvido: "Esqueceu sua senha?", 
      enter:"Entrem", 
      errLogin:"Nome de usuário/senha errado",
      showPass:"Mostrar senha" 
    }
  };
  

const Login = () => {
    const { setAuth } = useAuth();
    const { setCliente } = useContext(ClienteContext);
    const { language } = useContext(LanguageContext);
    const navigate = useNavigate();
    const location = useLocation();
    const from = location.state?.from?.pathname || "/";

    const userRef = useRef();
    const errRef = useRef();

    const text = translations[language];

    const [user, setUser] = useState('');
    const [pwd, setPwd] = useState('');
    const [errMsg, setErrMsg] = useState('');
    const [loading, setLoading] = useState(false);
    const [showPassword, setShowPassword] = useState(false);

    
    useEffect(() => {
        setErrMsg('');
    }, [user, pwd])

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        let usernameTrim = user.trim();
        let passwordTrim = pwd.trim();    
        try {
            const response = await axios.post("https://propietarios.pueblonuevo.com.ar/backend/login",
            {   username: usernameTrim,
                password: passwordTrim, 
            },
                {
                    headers: { 'Content-Type': 'application/json' ,
                               'Access-Control-Allow-Origin': '*'
                      },
                    withCredentials: false
                }
            );
            console.log (response);
            console.log(response.data.usuario.Nombre);          
            if (response?.data?.message) setErrMsg('Usuario y/o contraseña incorrectos.')
            else 
            {   let Nombre  = response.data.usuario.Nombre;
                let MercadoPago  = response.data.usuario.MercadoPago;
                let PayPal  = response.data.usuario.PayPal;
                const accessToken = response.data.usuario.accessToken;
                const roles = response.data.usuario.roles;
                let comisionMercadoPago  = response.data.comisiones.comisionMercadoPago;
                let comisionPayPal  = response.data.comisiones.comisionPayPal;
                setAuth({ user, pwd, roles, accessToken, Nombre, MercadoPago, PayPal, comisionMercadoPago, comisionPayPal });
                setUser('');
                setPwd('');
                setCliente ([{Nombre:Nombre}]);
                navigate(from, { replace: true });
            }
        } catch (err) {
            console.log (err);
            if (!err?.response) {
                setErrMsg(text.errLogin);
            } else if (err.response?.status === 400) {
                setErrMsg(text.errLogin);
            } else if (err.response?.status === 401) {
                setErrMsg(text.errLogin);
            } else {
                setErrMsg(text.errLogin);
            }
            errRef.current.focus();
        } finally {
            setLoading(false); // establecer la variable de estado loading en false
          }
    }

    return (

        <section className="login-container">
            <div className="top">
                <img src={Logo} alt="" className="landing-logo"/>
                
            </div>   
            
            <form className="login-form" onSubmit={handleSubmit}>
                <label htmlFor="username">{text.usuario}</label>
                <input
                    type="text"
                    id="username"
                    ref={userRef}
                    autoComplete="off"
                    onChange={(e) => setUser(e.target.value)}
                    value={user}
                    required
                />

                <label htmlFor="password">{text.password}</label>
                <input
                    type={showPassword ? "text" : "password"}
                    id="password"
                    onChange={(e) => setPwd(e.target.value)}
                    value={pwd}
                    required
                />
                <label htmlFor="show-password">
                    <input
                        type="checkbox"
                        id="show-password"
                        onChange={(e) => setShowPassword(e.target.checked)}
                    />
                    &nbsp;{text.showPass}
                </label>
                <button disabled={loading} style={{ display: 'flex', justifyContent: 'center', color:"#010F10", backgroundColor:"#68fb9b", width:"100px" }}>
                    {loading ? ( // mostrar el indicador de carga si loading es true
                        <SpinnerCircular сolor="#009EE3" />
                    ) : (
                        text.enter
                    )}
                </button>
                <p ref={errRef} className={errMsg ? "errmsg" : "offscreen"} aria-live="assertive">{errMsg}</p>
                <br/><br/><br/>
                <Link to="/reset-password" style={{color:"#F0F8FF"}}>{text.olvido}</Link>
            </form>
        </section>

    )
}

export default Login
