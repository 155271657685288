import { useNavigate } from "react-router-dom";
import { useContext, useState, useEffect } from "react";
import Contratos from "./Contratos";
import AuthContext from "../context/AuthProvider";
import Axios from "axios";
import "./../index.css";


const ContieneContratos = () => {
  const navigate = useNavigate();
  const [contratos, setContratos] = useState([{ message: "vacio" }]);
  
  const { auth } = useContext(AuthContext);  

  useEffect(() => {
    if (auth?.user) { 
      traeContratos();
    } else {
      navigate("/landing");
    }
  }, []);

  
  useEffect(() => {
    if (auth?.user) {
      //setClienteActual([{Nombre:auth.user}]);
    } else {
      navigate("/landing");
    }
  }, [auth]);

  
  const traeContratos = () => {
    let cliente = auth?.user;
    Axios.post(
      "https://propietarios.pueblonuevo.com.ar/backend/contratos",
      {
        cliente: cliente,
      },
      {
        headers: {
          "Access-Control-Allow-Origin": "https://propietarios.pueblonuevo.com.ar",
        },
      }
    ).then((response) => {
        //console.log ("Contratos", response.data);
      if (response.data.message) {
        //setContratosStatus(response.data.message);
      } else {
        setContratos(response.data);
      }
    });
  };

  return (
    <section>
      <div  style={{ display: "flex", justifyContent: "center", margin: "1px", maxWidth: "100%", maxHeight: "100%",  padding: "4px", marginTop:"5%", borderRadius: "10px", }}>
        {/* Aleja el borde superior para que no quede debajo de la barra */}
      </div>
      <div id="div-contratos" style={{ display: "flex", justifyContent: "center", margin: "1px", maxWidth: "100%", maxHeight: "100%", backgroundColor: "#01240F", padding: "4px", borderRadius: "10px",
          border: "0px solid",
          borderColor: "gray", }}>
        <Contratos key={contratos.Sik} contratos={contratos} />
      </div>
    </section>
  );
};

export default ContieneContratos;
