import { useNavigate } from "react-router-dom";
import { useContext, useState, useEffect } from "react";
import Carrito from "./Carrito";
import AuthContext from "../context/AuthProvider";
import CartContext from "../context/CartContext";
import LanguageContext from "../context/LanguajeContext";


const translations = {
  en: {
    lote: "Lot",
    manzana: "Block",
    contrato: "Contract",
    fechaFirma: "Signing Date",
    moneda: "Currency",
    formaDePago: "Payment Method",
    totalContratos: "Total contracts",
    cuotas: "Installments",
    carrito: "Shopping Cart"
  },
  es: {
    lote: "Lote",
    manzana: "Manzana",
    contrato: "Contrato",
    fechaFirma: "Fecha de firma",
    moneda: "Moneda",
    formaDePago: "Forma de Pago",
    totalContratos: "Total de contratos",
    cuotas: "Cuotas",
    carrito: "Carro de compras"
  },
  pt: {
    lote: "Lote",
    manzana: "Quadra",
    contrato: "Contrato",
    fechaFirma: "Data de Assinatura",
    moneda: "Moeda",
    formaDePago: "Forma de Pagamento",
    totalContratos: "Total de contratos",
    cuotas: "Parcelas",
    carrito: "Carrinho de Compras"
  }
};


const ContieneCarrito = () => {
  const { language } = useContext(LanguageContext);
  const [contratos] = useState([{ message: "vacio" }]);

  const navigate = useNavigate();
    
   
  const text = translations[language];
  
  useEffect(() => {
    console.log ("contiene carriro UE1");
    if (auth?.user) { 
      
    } else {

      navigate("/landing");
    }
  }, []);

  const { auth } = useContext(AuthContext);  

  useEffect(() => {
    console.log ("contiene carriro UE2");
    if (auth?.user) {
      
    } else {
      navigate("/landing");
    }
  }, [auth]);

   
  return (
    <section>
      <div style={{ display: "flex", justifyContent: "center", margin: "1px", maxWidth: "100%", maxHeight: "100%",  padding: "4px", marginTop:"5%", color:"#fff" }}>
        <h1 style={{ display: "flex", justifyContent: "center", margin: "1px", maxWidth: "100%", maxHeight: "100%",  padding: "4px", marginTop:"5%", color:"#fff" }}>{text.carrito}</h1>
      </div>
      <div id="div-contratos" style={{ display: "flex", justifyContent: "center", margin: "1px", maxWidth: "100%", maxHeight: "100%", backgroundColor: "#01240F", padding: "4px" }}>
        <Carrito key={contratos.Sik} contratos={contratos} />
      </div>
    </section>
  );
};

export default ContieneCarrito;
