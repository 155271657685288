import { useNavigate } from "react-router-dom";
import { useContext, useEffect } from "react";
import Cuotas from "./Cuotas";
import AuthContext from "../context/AuthProvider";
import LanguageContext from "../context/LanguajeContext";

import "./../index.css";

const ContieneCuotas = () => {
  
  const navigate = useNavigate();
     
  const { auth } = useContext(AuthContext);  

  useEffect(() => {
    //console.log ("contiene cuota UE2");
    if (auth?.user) {
      
    } else {
      navigate("/landing");
    }
  }, [auth]);

  return (
    <section>
      <div  style={{ display: "flex", justifyContent: "center", margin: "1px", maxWidth: "100%", maxHeight: "100%",  padding: "4px", marginTop:"5%", borderRadius: "10px", }}>
        {/* Aleja el borde superior para que no quede debajo de la barra */}
      </div>
      <div id="div-contratos" style={{ display: "flex", justifyContent: "center", margin: "1px", maxWidth: "100%", maxHeight: "100%", backgroundColor: "#01240F", padding: "4px", borderRadius: "10px",
          border: "0px solid",
          borderColor: "gray", }}>
        <Cuotas />
      </div>
    </section>
  );
};

export default ContieneCuotas;
