import { useState, useRef, useEffect, useContext } from 'react';
import "./Login.css";
import Logo from "./logo.png";
import { SpinnerCircular } from 'spinners-react';
import axios from '../api/axios';
import {useNavigate, Link } from 'react-router-dom';
import Swal from 'sweetalert2';
import LanguageContext from "../context/LanguajeContext";
import useAuth from '../hooks/useAuth';
const translations = {
    en: {
      nroCliente: "Client number",
      password: "Password",
      newPass: "New password",
      confirmPass: "Confirm password",
      changePass: "Change password",
      home: "Home"
    },
    es: {
        nroCliente: "Nro. de cliente",
        password: "Contraseña",
        newPass: "Nueva contraseña",
        confirmPass: "Confirmar contraseña",
        changePass: "Cambiar contraseña",
        home: "Volver al inicio"
    },
    pt: {
        nroCliente: "cliente não",
        password: "Senha",
        newPass: "Nova senha",
        confirmPass: "Confirmar senha",
        changePass: "Alterar senha",
        home: "Voltar ao início"
    }
};

function PasswordChange() {
    const { language } = useContext(LanguageContext);
    const { auth } = useAuth();
    const [customerNumber, setCustomerNumber] = useState("");
    const [password, setPassword] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const errRef = useRef();
    const [errMsg, setErrMsg] = useState('');

    const text = translations[language];

    useEffect(() => {
        if (auth?.user) {
            setCustomerNumber(auth.user);
        }
      }, [auth.user]);

    useEffect(() => {
        setErrMsg('');
    }, [customerNumber, password, confirmPassword])

    const handleSubmit = async (event) => {
        event.preventDefault();
        setLoading(true);

        if (newPassword !== confirmPassword) {
            setErrMsg('Las contraseñas no coinciden');
            setLoading(false);
            return;
        }

        try {
            const response = await axios.post("https://propietarios.pueblonuevo.com.ar/backend/changePassword",
            {
                usernumber: customerNumber,
                newPassword: newPassword, 
                currentPassword: password
            });

            console.log(response);

            Swal.fire({
                icon: 'success',
                title: '¡Éxito!',
                text: 'La contraseña ha sido cambiada con éxito.',
                timer: 2000,
                showConfirmButton: false,
            }).then(() => {
                navigate("/landing");
            });
            
        } catch (err) {
            if (!err?.response) {
                setErrMsg('No Server Response');
            } else {
                setErrMsg('Change Password Failed');
            }
        } finally {
            setLoading(false);
        }
    };

    return (
        <section className="login-container">
            <div className="top">
                <img src={Logo} alt="" className="landing-logo"/>
            </div>   
            <p ref={errRef} className={errMsg ? "errmsg" : "offscreen"} aria-live="assertive">{errMsg}</p>
            <h1>Pueblo Nuevo</h1>
            <br/><br/><br/>
            <form onSubmit={handleSubmit}>
                <input type="text" placeholder={text.nroCliente} style={{ height: '150px', fontSize: '20px', display: 'none' }} 
                    value={customerNumber} readOnly
                    />

                <input type="password" placeholder={text.password} style={{ height: '50px', width:'100%', fontSize: '20px' }} 
                    value={password} onChange={(e) => setPassword(e.target.value)} />

                <input type="password" placeholder={text.newPass} style={{ height: '50px', width:'100%',fontSize: '20px' }} 
                    value={newPassword} onChange={(e) => setNewPassword(e.target.value)} />       

                <input type="password" placeholder={text.confirmPass} style={{ height: '50px', width:'100%',fontSize: '20px' }} 
                    value={confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)} />

                <button style={{ display: 'flex', justifyContent: 'center', color:"#010F10", backgroundColor:"#68fb9b" }} disabled={loading}>
                    {loading ? (
                        <SpinnerCircular сolor="#009EE3" />
                    ) : (
                        text.changePass
                    )}
                </button>
                <br/><br/><br/>
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <Link to="/landing" style={{ color:"#FFF" }}>{text.home}</Link>
                </div>
            </form>
        </section>
    );
}

export default PasswordChange;
