import { useState } from 'react';
import Login from './components/Login';
import ContieneContratos from './components/ContieneContratos';
import Layout from './components/Layout';
import Cuotas from './components/Cuotas';
import Cart from './components/Carrito';
import ContieneCarrito from './components/ContieneCarrito';
import ContieneCuotas from './components/ContieneCuotas';
import Admin from './components/Admin';
import Missing from './components/Missing';
import Unauthorized from './components/Unauthorized';
import PasswordReset from './components/PasswordReset';
import PasswordChange from './components/PasswordChange';
import Landing from './components/Landing';
import Head from './components/Head';

import RequireAuth from './components/RequireAuth';
import { Routes, Route } from 'react-router-dom';
import ClienteContext from './context/ClienteContext';
import { CartProvider } from './context/CartContext';
import ContratosContext from "./context/ContratosContext";
import LanguageContext from "./context/LanguajeContext";

const ROLES = {
  'User': 2001,
  'Editor': 1984,
  'Admin': 5150
}

function App() {
  const [cliente, setCliente] = useState([]);
  const [selectedContrato, setSelectedContrato] = useState([]);
  const [language, setLanguage] = useState("es"); 
  const [isClicked, setIsClicked] = useState(true);  
  
  return (
    <LanguageContext.Provider value={{ language, setLanguage }}>
    <ClienteContext.Provider value={{ cliente, setCliente}}>
      <ContratosContext.Provider value={{ selectedContrato, setSelectedContrato}}>
      <CartProvider>
        <div  
          style={{ 
            transition: 'top 0.3s ease-in-out', 
            position: 'relative', 
            top: isClicked ? '0' : '-50px' }}>
              <Head isClicked={isClicked} setIsClicked={setIsClicked}/>
        </div>
      <Routes>
        <Route path="/" element={<Layout />}>
          {/* public routes */}
          <Route path="login" element={<Login />} />
          <Route path="Landing" element={<Landing />} />
          <Route path="cuotas" element={<Cuotas />} />
          <Route path="cart" element={<Cart />} />
          <Route path="contienecarrito" element={<ContieneCarrito />} />
          <Route path="contienecuotas" element={<ContieneCuotas />} />
          <Route path="unauthorized" element={<Unauthorized />} />
          <Route path="reset-password" element={<PasswordReset />} />
          <Route path="change-password" element={<PasswordChange />} />
          {/* we want to protect these routes 
          <Route element={<RequireAuth allowedRoles={[ROLES.User]} />}>*/}
            <Route path="/" element={<ContieneContratos />} />
          {/*</Route>*/}
          <Route path="admin" element={<Admin />} />
          
          {/*<Route element={<RequireAuth allowedRoles={[ROLES.Editor, ROLES.Admin]} />}>
            <Route path="lounge" element={<Lounge />} />
          </Route>*/}

          {/* catch all */}
          <Route path="*" element={<Missing />} />
        </Route>
      </Routes>
      </CartProvider>
      </ContratosContext.Provider>
    </ClienteContext.Provider>
    </LanguageContext.Provider>
  );
}

export default App;