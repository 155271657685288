import { useState, useRef, useEffect,useContext } from 'react';
import "./Login.css";
import Logo from "./logo.png";
import { SpinnerCircular } from 'spinners-react';
import axios from '../api/axios';
import {useNavigate, Link } from 'react-router-dom';
import Swal from 'sweetalert2';
import LanguageContext from "../context/LanguajeContext";

const translations = {
    en: {
      nroCliente: "Client number",
      solicitar: "Request password",
      email: "Email",
      home:"Home"
    },
    es: {
        nroCliente: "Nro. de cliente",
        solicitar: "Solicitar contraseña",
        email: "Correo electrónico",
        home:"Volver al inicio"
    },
    pt: {
        nroCliente: "cliente não",
        solicitar: "Pedir a senha", 
        email: "E-mail",
        home:"Voltar ao início"
    }
  };

function PasswordReset() {

    const { language } = useContext(LanguageContext);
    const [customerNumber, setCustomerNumber] = useState("");
    const [email, setEmail] = useState("");
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    const text = translations[language];

    useEffect(() => {
        setErrMsg('');
    }, [customerNumber, email])

    const errRef = useRef();
    const [errMsg, setErrMsg] = useState('');

    // Handler for form submission
    const handleSubmit = async (event) => {
        event.preventDefault();
        setLoading(true); 
        let usernameTrim = customerNumber.trim();
        let passwordTrim = email.trim();    
        try {
            /*const responseMail = await axios.post('https://propietarios.pueblonuevo.com.ar/backend/send-email', {
            // Puedes enviar los datos del correo en el cuerpo de la solicitud si lo necesitas
            to: 'destinatario@example.com',
            subject: 'Asunto del correo',
            text: 'Contenido del correo'
            });*/
            const response = await axios.post("https://propietarios.pueblonuevo.com.ar/backend/resetPassword",
            {   usernumber: usernameTrim,
                mail: passwordTrim, 
            },
                {
                    headers: { 'Content-Type': 'application/json' ,
                               'Access-Control-Allow-Origin': '*'
                      },
                    withCredentials: false
                }
            );

            // Se mostrará el mensaje de éxito
            Swal.fire({
                icon: 'success',
                title: '¡Éxito!',
                text: 'El cambio de contraseña fue exitoso. Revisa tu correo.',
                timer: 2000,
                showConfirmButton: false,
            }).then(() => {
                // Esto se ejecutará después de que se cierre la alerta
                navigate("/landing");
            });
            
            } catch (err) {
                if (!err?.response) {
                    setErrMsg('No Server Response');
                } else if (err.response?.status === 400) {
                    setErrMsg('Missing Username or Password');
                } else if (err.response?.status === 401) {
                    setErrMsg('Unauthorized');
                } else {
                    setErrMsg('Login Failed');
                }
                errRef.current.focus();
            } finally {
                setLoading(false); // establecer la variable de estado loading en false
            }
    };


    return (
        <section className="login-container">
            <div className="top">
                <img src={Logo} alt="" className="landing-logo"/>
            </div>   
            <p ref={errRef} className={errMsg ? "errmsg" : "offscreen"} aria-live="assertive">{errMsg}</p>
            <h1>Pueblo Nuevo</h1>
            <br/><br/><br/>
            <form onSubmit={handleSubmit}>
                <input type="text" placeholder={text.nroCliente} style={{ height: '50px', width:'100%', fontSize: '20px' }} 
                    value={customerNumber} onChange={(e) => setCustomerNumber(e.target.value)} />

                <input type="email" placeholder={text.email}  style={{ height: '50px', width:'100%', fontSize: '20px' }} 
                    value={email} onChange={(e) => setEmail(e.target.value)} />
                
                <button disabled={loading} style={{ display: 'flex', justifyContent: 'center', color:"#010F10", backgroundColor:"#68fb9b" }}>
                    {loading ? ( // mostrar el indicador de carga si loading es true
                        <SpinnerCircular сolor="#009EE3" />
                    ) : (
                        text.solicitar
                    )}
                </button>
                <br/><br/><br/>
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <Link to="/landing" style={{ color:"#FFF" }}>{text.home}</Link>
                </div>

            </form>
        </section>
    );
}

export default PasswordReset;
