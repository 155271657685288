import React, { useContext, useState, useEffect } from "react";
import { Card } from "@material-ui/core";
import ContratoContext from "../context/ContratosContext";
import LanguageContext from "../context/LanguajeContext";
import { useNavigate } from "react-router-dom";
import { SpinnerCircular } from "spinners-react";

const currentDate = new Date();

const translations = {
  en: {
    lote: "Lot",
    manzana: "Block",
    contrato: "Contract",
    fechaFirma: "Signing Date",
    moneda: "Currency",
    formaDePago: "Payment Method",
    totalContratos: "Total contracts",
    cuotas: "Installments",
    noHayCuotasPendientes: "No outstanding installments",
    cuotasImpagas: "Unpaid installments"
  },
  es: {
    lote: "Lote",
    manzana: "Manzana",
    contrato: "Contrato",
    fechaFirma: "Fecha de firma",
    moneda: "Moneda",
    formaDePago: "Forma de Pago",
    totalContratos: "Total de contratos",
    cuotas: "Cuotas",
    noHayCuotasPendientes: "No hay cuotas pendientes",
    cuotasImpagas: "Cuotas impagas"
  },
  pt: {
    lote: "Lote",
    manzana: "Bloco",
    contrato: "Contrato",
    fechaFirma: "Data de Assinatura",
    moneda: "Moeda",
    formaDePago: "Método de Pagamento",
    totalContratos: "Total de contratos",
    cuotas: "Parcelas",
    noHayCuotasPendientes: "Não há parcelas pendentes",
    cuotasImpagas: "Parcelas em aberto"
  }
};


const Contratos = ({ contratos }) => {
  const { setSelectedContrato } = useContext(ContratoContext);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const { language } = useContext(LanguageContext);
  const navigate = useNavigate();
  const text = translations[language];
  const handleVerCuotas = (contrato) => {
    setSelectedContrato(contrato);
    //console.log("Contrato es:", contrato);
    navigate("/ContieneCuotas");
  };

  useEffect(() => {
    if (!contratos) {
      setIsLoading(true);
    } else {
      setIsLoading(false);
      setIsLoaded(true);
      console.log ("contratos", contratos);
    }
    if (contratos && contratos.length > 0 && contratos[0].Sik) setIsLoaded(true)
    else setIsLoaded(false);
  }, [contratos]);


  const ContratoItem = (contrato) => {
    let fechaImpaga = contrato.FechaPrimeraCuotaImpaga;
    let fecha = null;

    if (fechaImpaga) {
      const [dia, mes, anio] = fechaImpaga.split("/");
      fecha = new Date(anio, mes - 1, dia);
    }


    return (
      <Card
        key={contrato.index}
        style={{
          margin: "10px",
          padding: "10px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-evenly",
          alignItems: "center",
          backgroundColor: "#010203",
          borderRadius: "10px",
          border: "2px solid",
          borderColor: "#00FF00",
          width: "240px",
          height: "300px",
          color: "#f2f2f2",
        }}
      >
        {/* borderColor:fecha && fecha < currentDate ? "red" : "#00FF00", Para que se ponga rojo si está vencida*/}
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginBottom: "5px",
          }}
        >
          <div>
            <div style={{ display: "flex", justifyContent: "center",fontSize: 16, fontWeight: "bold" }}>
              <span style={{ fontSize: 16 }}>
                {text.contrato} {contrato.Sik}
              </span>
            </div>
            <span style={{ display: "flex", justifyContent: "center",fontSize: 16, fontWeight: "bold" }}>
              {text.lote} {contrato.NroLote}
            &nbsp;&nbsp;
              {text.manzana} {contrato.Manzana}
            </span>
            <br/>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginBottom: "10px",
                justifyItems:"center"
              }}
            >
              <span style={{ fontSize: 14, justifyContent: "center" }}>
                {text.fechaFirma} {contrato?.FechaFirma}
              </span>
              <span style={{ fontSize: 14, justifyContent: "center" }}>
                {text.moneda} {contrato.Abreviacion}
              </span>
            </div>
            <div style={{ display: "flex", justifyContent: "center", fontSize: 14, marginTop:"10px" }}>
              <div>
                <span className="field-name">{text.formaDePago}:</span> {contrato.formaDePago}
              </div>
            </div>
          </div>
        </div>
        
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginBottom: "10px",
          }}
        ></div>
        {/*<div style={{ display: "flex", justifyContent: "center", fontSize: 14, backgroundColor: fecha && fecha < currentDate ? "#A00000" : "#008080", }}>
          <div>
            {contrato.FechaPrimeraCuotaImpaga && (
              <div >
                <span className="field-name">{text.cuotasImpagas} </span>
                {contrato.FechaPrimeraCuotaImpaga} 
              </div>
            )}
            
            {!contrato.FechaPrimeraCuotaImpaga && (
              <div>
                <span className="field-name">{text.noHayCuotasPendientes} </span>
              </div>
            )}
            </div>
          </div>
        */}
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginBottom: "10px",
          }}
        >
          {contrato.formaDePago !== "Contado" && isLoaded && (
            <button
              className="button"
              style={{
                fontSize: 14,
                fontWeight: "bold",
                width: "120px",
                backgroundColor: "#68fb9b",
                color:"#010F10"
              }}
              onClick={() => handleVerCuotas(contrato)}
            >
              {text.cuotas}
            </button>
          )}

        </div>
      </Card>
    );
  };
  //console.log("contratos en contratos", contratos);
  //console.log("languaje en contratos", language);
  return (
    <div
      style={{
        padding: "25px 25px",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        backgroundColor: "#01240F",
        width: "100%",
        height: "100%",
        borderRadius: "60px",
      }}
    >
      {isLoading ? (
        <div className="spinner-container">
          <SpinnerCircular />
        </div>
      ) : (
        <>
          <div
            className="contratos-list"
            style={{
              padding: "0 25px",
              display: "grid",
              gridTemplateColumns: "repeat(auto-fit, minmax(240px, 1fr))",
              gridGap: "10px",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
              minHeight: "calc(100% - 50px)",
            }}
          >
            {contratos && contratos.map((contrato) => <ContratoItem key={contrato.Sik} {...contrato} />)}
          </div>
          <div
            style={{
              marginTop: "50px",
              display: "flex",
              justifyContent: "center",
              textJustify: "auto",
              width: "100%",
            }}
          >
            {text.totalContratos}: {contratos.length}
          </div>
        </>
      )}
    </div>
  );
};

export default Contratos;
