import React, { createContext, useState, useEffect } from "react";

const CartContext = createContext();
export { CartContext };
export const CartProvider = ({ children }) => {
  const [cart, setCart] = useState([]);
  const [cartKey, setCartKey] = useState(0); // Agrega esta línea

  // Utiliza un efecto para verificar y cargar datos del carrito desde localStorage
  useEffect(() => {
    const storedCart = localStorage.getItem("cart");
    if (storedCart) {
      setCart(JSON.parse(storedCart));
    }
  }, []);

  // Utiliza un efecto para actualizar localStorage cada vez que el estado del carrito cambie
  useEffect(() => {
    //console.log ("cart UE", cart);
    if (cart.length > 0) {
      localStorage.setItem("cart", JSON.stringify(cart));
    } else {
      localStorage.removeItem("cart");
    }
  }, [cart]);

  // Función para agregar una cuota al carrito
  /*const addToCart = (cuota) => {
    setCartKey((prevKey) => prevKey + 1); // Incrementa el contador
    setCart([...cart, { ...cuota, key: cartKey }]);
  };*/

  const addToCart = (cuota) => {
    // Verificar si la cuota ya está en el carrito
    const cuotaExists = cart.find((item) => item.Sik === cuota.Sik);
    
    // Solo agregar la cuota si no está en el carrito
    if (!cuotaExists) {
      setCartKey((prevKey) => prevKey + 1); // Incrementa el contador
      setCart([...cart, { ...cuota, key: cartKey }]);
    } else {
      //alert("La cuota ya ha sido agregada al carrito");
    }
  };

  // Función para borrar una cuota del carrito
  const removeFromCart = (index) => {
    //console.log ("cart RFC", cart, index);
    const newCart = [...cart];
    newCart.splice(index, 1);
    setCart(newCart);
  };

  const clearCart = () => {
    setCart([]);
  };


  return (
    <CartContext.Provider value={{ cart, setCart, addToCart, removeFromCart, clearCart }}>
      {children}
    </CartContext.Provider>
  );
};

export default CartContext;
