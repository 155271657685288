import { Link } from "react-router-dom"
import  LoteSelection  from "./LoteSelection";

const Admin = () => {
    const manzanas = [
        { numero: 1, lotes: 4 },
        { numero: 2, lotes: 3 },
        { numero: 3, lotes: 5 },
        { numero: 4, lotes: 6 },
        { numero: 5, lotes: 2 }
      ];
    return (
        <section>
            <h1>Administrador</h1>
            <br />
            <p>Seleccione el lote y la manzana</p>
            <LoteSelection manzanas={manzanas} />
            <div className="flexGrow">
                <Link to="/">Home</Link>
            </div>
        </section>
    )
}

export default Admin
