import React, { createContext, useState, useEffect } from "react";

const ContratosContext = createContext();

export const ContratosProvider = ({ children }) => {
  const [contratos, setContratos] = useState([]);
  const [selectedContrato, setSelectedContrato] = useState(null);

  // Utiliza un efecto para verificar y cargar datos de contratos desde localStorage
  useEffect(() => {
    const storedContratos = localStorage.getItem("contratos");
    if (storedContratos) {
      setContratos(JSON.parse(storedContratos));
    }
  }, []);

  // Utiliza un efecto para verificar y cargar el contrato seleccionado desde localStorage
  useEffect(() => {
    const storedSelectedContrato = localStorage.getItem("selectedContrato");
    if (storedSelectedContrato) {
      setSelectedContrato(JSON.parse(storedSelectedContrato));
    }
  }, []);

  // Utiliza un efecto para actualizar localStorage cada vez que el estado de contratos cambie
  useEffect(() => {
    if (contratos.length > 0) {
      localStorage.setItem("contratos", JSON.stringify(contratos));
    } else {
      localStorage.removeItem("contratos");
    }
  }, [contratos]);

  // Utiliza un efecto para actualizar localStorage cada vez que el estado del contrato seleccionado cambie
  useEffect(() => {
    if (selectedContrato) {
      localStorage.setItem("selectedContrato", JSON.stringify(selectedContrato));
    } else {
      localStorage.removeItem("selectedContrato");
    }
  }, [selectedContrato]);

  return (
    <ContratosContext.Provider
      value={{
        contratos,
        setContratos,
        selectedContrato,
        setSelectedContrato,
      }}
    >
      {children}
    </ContratosContext.Provider>
  );
};

export default ContratosContext;
