import React, { useContext } from "react";
import flagES from "./es_flag.png";
import flagEN from "./en_flag.png";
import flagPT from "./pt_flag.png";
import carrito from "./carrito.png";
import flechaArriba from "./flechaarriba.png";
import flechaAbajo from "./flechaabajo.png";
import iconContrato from "./contratos.png";
import useAuth from '../hooks/useAuth';
import { useNavigate } from "react-router-dom";
import LanguageContext from "../context/LanguajeContext";
import CartContext from "../context/CartContext";
import { IconButton, Menu, MenuItem } from "@material-ui/core";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import Logo from "./logoColor.png"
const Head = ({ isClicked, setIsClicked  }) => {
  const { setLanguage } = useContext(LanguageContext);
  const { cart, clearCart } = useContext(CartContext);
  const changeLanguage = (newLanguage) => {
    setLanguage(newLanguage);
  };
  const { auth, setAuth } = useAuth();

  const navigate = useNavigate();

  const logoStyle = {
    width: "60px",
    height: "auto",
    marginRight: "10px"
  };


  const flagButtonStyle = {
    border: "none", 
    background: "none", 
    padding: "0px", 
    margin: "0px",
    marginTop: "60px",
    marginRight: "10px",
    height: '33px', // Asegúrate que este valor se ajuste correctamente según tus necesidades
    display: 'flex', 
    alignItems: 'center', // Asegúrate que las imágenes estén centradas en el botón
  };
  
  const flagStyle = {
    width: "30px",
    height: "30px",
    padding: "0px",
    margin: "0px",
    cursor: "pointer",
  };

  const iconStyle = {
    width: "30px",
    height: "auto",
    marginRight: "1px",
    cursor: "pointer",
  };

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleChangePassword = () => {
    navigate("/change-password");
    handleClose();
  };

  const handleLogout = async () => {
    localStorage.removeItem("user");
    clearCart();
    setAuth({});
    handleClose();
    navigate("/landing");
  };


  //console.log ("HEAD auth.Nombre", auth?.Nombre, auth)

  return (
    <div
      style={{
        width: "100%",
        height: "10%",
        backgroundColor: "#010203",
        position: "fixed",
        zIndex: 1000,
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        padding: "0 10px",
        border: "2px solid grey",
        borderRadius: "10px",
      }}
    >
      <div>
        <img src={Logo} alt="Logo" style={logoStyle} />
        {auth?.user && (
          <>  
            <button
                onClick={() => navigate("/")}
                style={{ border: "none", background: "none" }}
            >
                <img src={iconContrato} alt="Icono 1" style={iconStyle} />
            </button>
          </>
        )}
        {cart.length > 0 && (
          <button
            onClick={() => navigate("/ContieneCarrito")}
            style={{ border: "none", background: "none" }}
          >
            <img src={carrito} alt="Carrito" style={iconStyle} />
          </button>
        )}
      </div>
      <div style={{ display: "flex", alignItems: "" }}>
        
        {auth?.user && (
          <>
            <span style={{ color: "#fff", marginRight: "10px", marginTop: "30px", fontSize: "20px" }}>{auth.Nombre}</span>
            <IconButton
              edge="end"
              color="inherit"
              aria-label="account"
              onClick={handleMenu}
            >
            <AccountCircleIcon style={{ color: "white", marginRight: "15px" }} />
            </IconButton>
            <Menu
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              <MenuItem onClick={handleChangePassword}>Cambiar contraseña</MenuItem>
              <MenuItem onClick={handleLogout}>Logout</MenuItem>
            </Menu>
          </>
        )}
        <button onClick={() => setIsClicked(!isClicked)}
          style={{ border: "none", background: "none" }}
        > 
          {isClicked ? <img src={flechaArriba} alt="Logo" style={{ ...iconStyle, marginRight: 0, marginTop: 0 }} /> : <img src={flechaAbajo} alt="Logo" style={{ ...iconStyle, marginRight: 0, marginTop: 50 }} />}
          </button> 
          <div style={{ display: "flex", flexDirection: "row", alignItems: "center", height: '30px', justifyContent: 'stretch'  }}>
            <button
              onClick={() => changeLanguage("es")}
                style={flagButtonStyle}
            >
              <img src={flagES} alt="Español" style={flagStyle} />
            </button>

            <button
              onClick={() => changeLanguage("pt")}
                style={flagButtonStyle}
            >
              <img src={flagPT} alt="Portugues" style={flagStyle} />
            </button>
            
            <button
              onClick={() => changeLanguage("en")}
                style={flagButtonStyle}
            >
              <img src={flagEN} alt="English" style={flagStyle} />
            </button>
        </div>
      </div>
      
    </div>
  );
};

export default Head;

   
