import React, { createContext, useState, useEffect } from "react";

const LanguageContext = createContext();

export const LanguajeProvider = ({ children }) => {
  const [lang, setLang] = useState("en");

  // Utiliza un efecto para verificar y cargar datos de usuario desde localStorage
  useEffect(() => {
    const storedLang = localStorage.getItem("lang");
    if (storedLang) {
      setLang(JSON.parse(storedLang));
    }
  }, []);

  // Utiliza un efecto para actualizar localStorage cada vez que el estado de lang cambie
  useEffect(() => {
    if (lang) {
      localStorage.setItem("lang", JSON.stringify(lang));
    } else {
      localStorage.removeItem("lang");
    }
  }, [lang]);

  return (
    <LanguageContext.Provider value={{ lang, setLang }}>
      {children}
    </LanguageContext.Provider>
  );
};

export default LanguageContext;
